const QRCode = require("qrcode");
const html2pdf = require("html2pdf.js");

// utils
function createHref(link, text) {
  return `<a href="${link}" target="_blank">${text}</a>`;
}

function isScrolledIntoView(el) {
  const rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom <= window.innerHeight;
}

function clearError() {
  const errorMessageContainer = document.querySelector(
    "#error-message-container"
  );
  errorMessageContainer.classList.add("w3-hide");
}

function showError(error) {
  const errorMessage = document.querySelector("#error-message");
  const errorMessageContainer = document.querySelector(
    "#error-message-container"
  );
  errorMessage.innerHTML = (error.error && error.error.message) || error.message || error;
  errorMessageContainer.classList.remove("w3-hide");
}

function clearOutput() {
  const messageContainer = document.querySelector("#output");
  messageContainer.innerHTML = "";
}

function showOutput(message, append = true) {
  const messageContainer = document.querySelector("#output");
  messageContainer.innerHTML =
    (append ? messageContainer.innerHTML + "<br/>" : "") + message;
}

function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

function reloadPage() {
  window.location.href = window.location.href;
}

// data requests

const BASE_URL = window.location.href.indexOf("localhost") > 0
  ? "http://localhost:3000"
  : "https://concer.tv";
const PUBLIC_API_URL = `${BASE_URL}/api`;
const ADMIN_URL = `${BASE_URL}/api/admin`;

const makeRequest = async ({ endpoint, method, body }) => {
  if (!auth.state) {
    showError("Authorise to use this console");
    refreshAccess();
  } else {
    try {
      return await sanitizeResponse(
        fetch(`${ADMIN_URL}${endpoint}`, {
          method,
          body: JSON.stringify(body),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${auth.state.idToken}`
          },
        })
      );
    } catch (err) {
      if (err && err.statusCode && err.statusCode === 401) {
        logout();
      } else {
        throw err;
      }
    }
  }
};

const createReferral = (desiredRef) => {
  return makeRequest({
    endpoint: "/referral",
    method: "POST",
    body: {
      desiredRef,
    }
  });
};

const createRedeemCode = (productId) => {
  return makeRequest({
    endpoint: "/redeem/code/create",
    method: "POST",
    body: {
      productId,
    },
  });
};

const sendReminders = (eventId) => {
  return makeRequest({
    endpoint: "/reminder",
    method: "POST",
    body: {
      event: eventId,
      preview: false,
    },
  });
};

const searchTickets = (query) => {
  return makeRequest({
    endpoint: `/tickets?q=${query}`,
    method: "GET",
  });
};

const getEvents = () => {
  return makeRequest({
    endpoint: "/events?i=1",
    method: "GET",
  });
};

const getOrders = (idList) => {
  return makeRequest({
    endpoint: `/orders?o=${idList.join(",")}`,
    method: "GET",
  });
};

const getTokens = (ticketIds) => {
  return makeRequest({
    endpoint: `/tokens?ti=${ticketIds.join(",")}`,
    method: "GET",
  });
};

const getProducts = (includeInactive = false) => {
  return makeRequest({
    endpoint: `/products?i=${includeInactive === true}`,
    method: "GET",
  });
};

const getTranslations = (language) => {
  return makeRequest({
    endpoint: `/translations?lang=${language}`,
    method: "GET",
  });
};

const createBatch = ({ series, amount, productId }) => {
  return makeRequest({
    endpoint: `/batch`,
    method: "POST",
    body: {
      series,
      amount,
      productId,
    },
  });
};

const searchBatches = (query) => {
  return makeRequest({
    endpoint: `/batches`,
    method: "GET",
  });
};

const getBatch = (batchId) => {
  return makeRequest({
    endpoint: `/batch?b=${batchId}&t=1`,
    method: "GET",
  });
};

const enableBatch = (batchId) => {
  return makeRequest({
    endpoint: `/batch?b=${batchId}&s=1`,
    method: "PUT",
  });
};

const disableBatch = (batchId) => {
  return makeRequest({
    endpoint: `/batch?b=${batchId}&s=0`,
    method: "PUT",
  });
};

const deleteBatch = (batchId) => {
  return makeRequest({
    endpoint: `/batch?b=${batchId}`,
    method: "DELETE",
  });
};

const searchStaff = (query = {}) => {
  const params = query && [
    query.profileId && `p=${query.profileId}`,
  ] || [];
  return makeRequest({
    endpoint: `/profile/staff?${params.join("&")}`,
    method: "GET",
  });
};

const updateStaff = ({ profileId, status, email, allowedLocations, role }) => {
  return makeRequest({
    endpoint: `/profile/staff`,
    method: "POST",
    body: {
      profileId,
      email,
      status,
      allowedLocations,
      role,
    }
  });
};

const refundTicket = (ticketId, amount, comment) => {
  return makeRequest({
    endpoint: `/refund`,
    method: "POST",
    body: {
      ticketId, amount, comment,
    }
  });
};

const updateTicketEmail = (ticketId, email) => {
  return makeRequest({
    endpoint: `/ticket`,
    method: "PUT",
    body: {
      id: ticketId,
      email,
    }
  });
};

const resendTicketEmail = (ticketId) => {
  return makeRequest({
    endpoint: `/ticket`,
    method: "PUT",
    body: {
      id: ticketId,
    }
  });
};

const sanitizeResponse = async (resPromise) => {
  try {
    const res = await resPromise;
    const json = await res.json();

    if (res.status >= 400) {
      console.error("Fetched error response", json);

      throw json;
    }
    return {
      statusCode: res.status,
      response: json,
    };
  } catch (err) {
    console.error("Error when trying to fetch output", err);
    // oddly the .json() function converts `status` into `statusCode` on the response object
    throw err.statusCode ? err : {
      statusCode: 500,
      error: err,
    };
  }
};

// listeners

const createListeners = (selector, event, fn) => {
  var elements = document.querySelectorAll(selector);
  [].slice.apply(elements).forEach((element) => {
    element.addEventListener(event, fn);
  });
};

const addOnLoadListener = (selector, event, fn) =>
  addOnLoadAction(() => {
    createListeners(selector, event, fn);
  });

const addOnLoadAction = (fn) =>
  document.addEventListener("DOMContentLoaded", fn, false);

addOnLoadListener(".nav-tab", "click", (e) => {
  var tabName = e.target.dataset.tab;
  clearError();
  const tabs = document.getElementsByClassName("content");
  [].slice.apply(tabs).forEach((e) => {
    e.classList.add("w3-hide");
  });
  document.getElementById(tabName).classList.remove("w3-hide");
});

addOnLoadListener("#postReferral", "click", async (e) => {
  clearError();
  const refCode = document.querySelector("#refCode").value;
  try {
    const res = await createReferral(refCode);
    const ref = res && res.response.ref;
    showOutput(`Referral code: ${ref}, link: ${BASE_URL}?ref=${ref}`);
  } catch (err) {
    showError(err);
  }
});

addOnLoadListener("#postRedeemCode", "click", async (e) => {
  clearError();
  const productId = document.querySelector("#productId").value;
  try {
    const res = await createRedeemCode(productId)
    const code = res && res.response.code;

    clearError();
    showOutput(`Code: ${code}, product: ${productId}`);
  } catch (err) {
    showError(err);
  }
});

addOnLoadListener("#postReminder", "click", async (e) => {
  e.target.disabled = true;
  clearError();
  const eventId = document.querySelector("#eventId").value;
  try {
    const { response: res } = await sendReminders(eventId);
    showOutput(res);
  } catch (err) {
    showError(err);
  }
});

addOnLoadAction((e) => {
  //TODO add check for selected tab
  loadTicketSearch();
  loadBatchesSearch();
  loadStaffSearch();
});

addOnLoadAction((e) => {
  loadOptions();
});

const loadOptions = async () => {
  const selectEl = document.querySelector(".product-selector");

  const { response: products } = await getProducts(false);
  const { response: translations } = await getTranslations("en");

  Object.values(products).forEach(product => {
    const optionEl = document.createElement("option");
    optionEl.value = product.id;
    optionEl.text = `${translations[product.title]} - ${translations[product.subtitle]}`;
    selectEl.appendChild(optionEl);
  });
};

const determineOrderChannel = (trx) => {
  if (trx.TransactionId) {
    return "vivawallet";
  } else if (trx.payment_intent) {
    return "stripe";
  } else if (trx.capture) {
    return "paypal";
  } else {
    return "unknown";
  }
};

const formatAmount = (amount) => {
  return ((amount || 0) / 100).toFixed(2);
};

const loadTicketSearch = async () => {
  const query = getQueryParam("tq");
  if (!query) {
    return;
  }
  document.querySelector("#ticketQueryInput").value = query;

  const tableContent = document.querySelector("#tickets-content");
  try {
    const { response: tickets } = await searchTickets(query);

    if (!tickets || !tickets.length) {
      console.error("No ticket results", response);
      throw new Error("No results");
    }

    const orderIdList = tickets.map((t) => t.orderId).filter((x) => x);
    const ticketIdList = tickets.map((t) => t.id);

    // TODO parallelise the requests
    const { response: events } = await getEvents();

    const { response: dict } = await getTranslations("en");

    const { response: products } = await getProducts(true);

    const { response: orders } = await getOrders(orderIdList);

    const { response: tokens } = await getTokens(ticketIdList);

    const sortedCreationDateAsc = tickets.sort((a, b) => a.createdOn < b.createdOn ? -1 : 1);

    const rows = sortedCreationDateAsc.map((ticket) => {
      const order =
        orders.find((order) => ticket.orderId === order.id) || {};
      const successfulTransaction = order.successfulTransaction || {};
      const channel = order.channel || determineOrderChannel(successfulTransaction);
      const refund = ticket.refund || (order.refunds && order.refunds[0]);
      const totalAmount = formatAmount(order.totalAmount);
      const refundedAmount = formatAmount(order.refundedAmount);
      const remainingAmount = order.totalAmount - (order.refundedAmount || 0);
      const cardNumber =
        (ticket.paymentDetails.panDigits &&
          ticket.paymentDetails.panDigits.padStart(8, "*")) ||
        "Unknown";
      const orderId = ticket.orderId || "[Free ticket]";
      const ticketTokens = tokens.filter(token => token.ticketId === ticket.id);

      return `<tr>
      <td>
        ${createHref(`${BASE_URL}/ticket?t=${ticket.id}`, ticket.id)}
      </td>
      <td>${ticket.createdOn}</td>
      <td>${ticket.paymentDetails.email}</td>
      <td>${ticket.product}
      <br/>${ticket.events.map(eventId => {
        const event = (events.find(e => e.id === eventId) || {});
        const eventTitle = dict[event.title];
        const eventAttendance = ticketTokens.find(token => token.eventId === event.id);
        return `${eventTitle} ${eventAttendance ? "✓" : ""}`;
      }).join("<br/>")
        }</td>
      <td>
        ${(successfulTransaction.receipt_url &&
          createHref(successfulTransaction.receipt_url, orderId)) ||
        orderId
        }
        <br/>Paid: ${totalAmount}
        ${refundedAmount > 0 ? `<br/>Refunded: ${refundedAmount}` : ""}
      </td>
      <td>
        ${(successfulTransaction.payment_intent &&
          createHref(
            `https://dashboard.stripe.com/payments/${successfulTransaction.payment_intent}`,
            cardNumber
          )) ||
        cardNumber
        }
        <br/>${channel}
      </td>
      <td>
        ${ticket.cancelledOn ? `
        Cancelled on: ${ticket.cancelledOn || ""}
        <br/>Comment: ${ticket.comment || ""}
        ` : ""}
        ${refund ? `
        <br/>Amount: ${formatAmount(refund.amount)}
        <br/>Date: ${refund.refundedOn}
        <br/>Ref: ${channel === "stripe" ?
            createHref(successfulTransaction.receipt_url, refund.refundId) :
            channel === "paypal" ?
              createHref((refund.refundResponse.links.find(l => l.rel === "self") || {}).href, refund.refundId) :
              refund.refundId}
        ` : ""}
      </td>
      <td>
        ${!ticket.cancelledOn
          ? `
            <div>
              <button class="resend-ticket-btn" data-ticket-id="${ticket.id}">Resend</button>
            </div>
            <div>
              <button class="edit-ticket-btn" data-ticket-id="${ticket.id}">Edit email</button>
            </div>
            ${ticket.orderId ?
            `<div>
                <button class="cancel-ticket-btn"
                  data-ticket-id="${ticket.id}"
                  data-remaining-amount="${remainingAmount}">Cancel</button>
              </div>` : ""
          }
            `
          : ""
        }
      </td>
      </tr>`;
    });

    tableContent.innerHTML = rows.join("");

    createListeners(".cancel-ticket-btn", "click", (e) => {
      const ticketId = e.target.dataset.ticketId;
      const remainingAmount = e.target.dataset.remainingAmount;

      const ticket = tickets.find(t => t.id === ticketId);

      const product = products[ticket.product];

      const amount = formatAmount((product && product.amount) || remainingAmount);

      showRefundDialog(ticket, amount);
    });

    createListeners(".edit-ticket-btn", "click", async (e) => {
      const ticketId = e.target.dataset.ticketId;
      var email = prompt("Please insert new email");
      if (email != null && email.length > 0) {
        try {
          const { response: res } = await updateTicketEmail(ticketId, email);
          loadTicketSearch();
        } catch (err) {
          showError(err);
        }
      } else {
        showError("New email is mandatory");
      }
    });

    createListeners(".resend-ticket-btn", "click", async (e) => {
      const ticketId = e.target.dataset.ticketId;
      try {
        const { response: res } = await resendTicketEmail(ticketId)
        alert("Resent ticket email successfully");
      } catch (err) {
        showError(err);
      }
    });
  } catch (err) {
    showError(err);
  }
};

// refund modal
function closeRefundOverlay() {
  document.querySelector("#refund-overlay").classList.remove("open");
}

function openRefundOverlay() {
  document.querySelector("#refund-overlay").classList.add("open");
  enableRefundSubmitForm(true);
}

const showRefundDialog = (ticket, amount) => {
  document.querySelector("#refund-ticketId").value = ticket.id;
  document.querySelector("#refund-amount").value = amount;
  document.querySelector("#refund-comment").value = "";

  openRefundOverlay();
};

const enableRefundSubmitForm = (status) => {
  document.querySelector("#refund-submit").disabled = !status;
};

addOnLoadListener("#refund-submit", "click", async () => {
  const amount = document.querySelector("#refund-amount").value * 100;
  const comment = document.querySelector("#refund-comment").value;
  const ticketId = document.querySelector("#refund-ticketId").value;

  try {
    if (!ticketId || !ticketId.length) {
      throw new Error("Empty ticketId, please reload the page");
    }
    if (!amount) {
      throw new Error("Amount is mandatory");
    }
    if (!comment || !comment.length) {
      throw new Error("Comment is mandatory - feel free to describe the current circumstances");
    }

    enableRefundSubmitForm(false);

    const { response: res } = await refundTicket(ticketId, amount, comment);

    closeRefundOverlay();

    loadTicketSearch();
  } catch (err) {
    showError(err);
  } finally {
    enableRefundSubmitForm(true);
  }
});

//batches
const loadBatchesSearch = async () => {
  const query = getQueryParam("bq");
  // if (!query) {
  //   return;
  // }
  // document.querySelector("#batchQueryInput").value = query;

  const tableContent = document.querySelector("#batches-content");
  try {
    const { response: batches } = await searchBatches(query);

    if (!batches || !batches.length) {
      throw new Error("No results");
    }

    const rows = batches.map((batch) => {
      return `<tr>
      <td>
        <div class="${batch.cancelledOn ? "cancelled-row" : ""}">
          ${batch.id}
        </div>
      </td>
      <td>
        <div class="${batch.cancelledOn ? "cancelled-row" : ""}">
          ${batch.createdOn}
        </div>
        ${batch.cancelledOn ? `<div>Cancelled on ${batch.cancelledOn}</div>` : ""}
      </td>
      <td>
        <div class="${batch.cancelledOn ? "cancelled-row" : ""}">
          ${batch.createdBy}
        </div>
        ${batch.cancelledOn ? `<div>Cancelled by ${batch.cancelledBy}</div>` : ""}
      </td>
      <td>${batch.series} ${batch.start}-${batch.end}</td>
      <td>
        ${batch.activatedOn
          ? `<div class="${batch.deactivatedOn ? "cancelled-row" : ""}">
              <div>Activated on ${batch.activatedOn} (by ${batch.activatedBy})</div>
            </div>`
          : "<i>Not activated yet</i>"
        }
        ${batch.deactivatedOn
          ? `<div>Deactivated on ${batch.deactivatedOn} (by ${batch.deactivatedBy})</div>`
          : ""
        }
        </div>
      </td>
      <td>${batch.productId}</td>
      <td>
        ${!batch.cancelledOn
          ? `<div>
              ${!batch.activatedOn || batch.deactivatedOn
            ? `<button class="activate-batch-btn" data-batch-id="${batch.id}">Activate</button>`
            : `<button class="deactivate-batch-btn" data-batch-id="${batch.id}">Deactivate</button>`
          }
            </div>
            <div>
              <button class="print-batch-btn" data-batch-id="${batch.id}">Print</button>
            </div>
            <div>
              <button class="cancel-batch-btn" data-batch-id="${batch.id}">Cancel</button>
            </div>`
          : ""}
      </td>
      </tr>`;
    });

    tableContent.innerHTML = rows.join("");

    createListeners(".activate-batch-btn", "click", async (e) => {
      const batchId = e.target.dataset.batchId;
      if (confirm(`Activate the batch ${batchId}?`)) {
        e.target.disabled = true;
        try {
          const { response: res } = await enableBatch(batchId);
          loadBatchesSearch(query);
        } catch (err) {
          showError(err);
        }
      }
    });

    createListeners(".deactivate-batch-btn", "click", async (e) => {
      const batchId = e.target.dataset.batchId;
      if (confirm(`Deactivate the batch ${batchId}?`)) {
        e.target.disabled = true;
        try {
          const { response: res } = await disableBatch(batchId);
          loadBatchesSearch(query);
        } catch (err) {
          showError(err);
        }
      }
    });

    createListeners(".cancel-batch-btn", "click", async (e) => {
      const batchId = e.target.dataset.batchId;
      if (confirm(`Cancel the batch ${batchId}?`)) {
        e.target.disabled = true;
        try {
          const { response: res } = await deleteBatch(batchId);
          loadBatchesSearch(query);
        } catch (err) {
          showError(err);
        }
      }
    });

    createListeners(".print-batch-btn", "click", async (e) => {
      const batchId = e.target.dataset.batchId;

      const { response: { batch, tickets } } = await getBatch(batchId);

      const batchTitle = document.querySelector("#batch-title");
      batchTitle.innerHTML = `Batch ${batch.series} ${batch.start}-${batch.end}`;

      const printContainer = document.querySelector("#print-container");
      const ticketEls = tickets
        .filter(ticket => ticket.batchInfo)
        .sort((a, b) => {
          return a.batchInfo.num - b.batchInfo.num;
        })
        .map(ticket => {
          const ticketContainerId = `batch-ticket-qrcode-${ticket.id}`;
          return `<div class="batch-ticket-container">
          <div class="batch-ticket-qrcode">
            <canvas id="${ticketContainerId}"></canvas>
          </div>
          <div class="batch-ticket-info">
            <div class="batch-ticket-series">
              ${ticket.batchInfo.series.toUpperCase()}
            </div>
            <div class="batch-ticket-num">
              ${ticket.batchInfo.num}
            </div>
          </div>
        </div>`;
        })
        .join("");

      printContainer.innerHTML = `<div class="batch-tickets-container">
        ${ticketEls}
      </div>`;

      tickets.forEach(ticket => QRCode.toCanvas(
        document.getElementById(`batch-ticket-qrcode-${ticket.id}`),
        ticket.id,
        (err) => {
          err && console.error(err);
        }
      ));

      openBatchPrintOverlay();
      printElement(printContainer, `batch-${batch.series}${batch.start}-${batch.end}.pdf`);
    });
  } catch (err) {
    showError(err);
  }
};

const printElement = (element, filename) => {
  html2pdf()
    .from(element)
    .set({
      margin: 0,
      filename,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "A4", orientation: "landscape" }
    })
    .save();
};

// create batch modal

addOnLoadListener("#create-batch", "click", (e) => {
  openCreateBatchOverlay();
});

function closeCreateBatchOverlay() {
  document.querySelector("#create-batch-overlay").classList.remove("open");
}

function openCreateBatchOverlay() {
  document.querySelector("#create-batch-overlay").classList.add("open");
  enableCreateBatchSubmitForm(true);
}

const showCreateBatchDialog = (ticket, amount) => {
  document.querySelector("#create-batch-series"); series.value = "";
  document.querySelector("#create-batch-amount").value = 1;
  document.querySelector("#create-batch-productId").value = "";

  openCreateBatchOverlay();
};

const enableCreateBatchSubmitForm = (status) => {
  document.querySelector("#create-batch-submit").disabled = !status;
};

addOnLoadListener("#create-batch-submit", "click", async () => {
  const series = document.querySelector("#create-batch-series").value;
  const amount = document.querySelector("#create-batch-amount").value * 1;
  const productId = document.querySelector("#create-batch-productId").value;

  try {
    if (!series || !series.length) {
      throw new Error("Series is mandatory");
    }
    if (!amount) {
      throw new Error("Number is mandatory");
    }
    if (!productId || !productId.length) {
      throw new Error("productId is mandatory, please reload the page");
    }

    enableCreateBatchSubmitForm(false);

    const { response: res } = await createBatch({ series, amount, productId });

    closeCreateBatchOverlay();

    loadBatchesSearch();
  } catch (err) {
    showError(err);
  } finally {
    enableCreateBatchSubmitForm(true);
  }
});

// batch print modal

function closeBatchPrintOverlay() {
  document.querySelector("#batch-print-overlay").classList.remove("open");
}

function openBatchPrintOverlay() {
  document.querySelector("#batch-print-overlay").classList.add("open");
}

const showBatchPrintDialog = () => {
  openBatchPrintOverlay();
};

// staff

const loadStaffSearch = async () => {
  const query = getQueryParam("sq");
  // document.querySelector("#staffQueryInput").value = query;

  const tableContent = document.querySelector("#staff-content");
  try {
    const { response: staff } = await searchStaff(query);

    if (!staff || !staff.length) {
      throw new Error("No results");
    }

    const rows = staff.map((profile) => {
      const validator = profile.validator;
      const seller = profile.seller;
      return `<tr>
      <td>
        ${profile.id}
      </td>
      <td>
        ${profile.email}<br/>
        ${profile.firstName || ""} ${profile.lastName || ""}
      </td>
      <td>
        ${validator ? `
          <div><b>Validator</b></div>
          <div>Locations: ${validator.allowedLocations && validator.allowedLocations.join(", ")}</div>
          <div class="${validator.disabledOn ? "cancelled-row" : ""}">
            Enabled on ${validator.enabledOn} (by ${validator.enabledBy})
          </div>
          ${validator.disabledOn
            ? `<div>Disabled on ${validator.disabledOn} (by ${validator.disabledBy})</div>`
            : ""
          }` : ""
        }
        ${seller ? `
          <div><b>Seller</b></div>
          <div class="${seller.disabledOn ? "cancelled-row" : ""}">
            Enabled on ${seller.enabledOn} (by ${seller.enabledBy})
          </div>
          ${seller.disabledOn
            ? `<div>Disabled on ${seller.disabledOn} (by ${seller.disabledBy})</div>`
            : ""
          }` : ""
        }
      </td>
      <td>
        <div>
          <button class="edit-staff-btn" data-profile-id="${profile.id}">Edit</button>
        </div>
      </td>
      </tr>`;
    });

    tableContent.innerHTML = rows.join("");

    createListeners(".edit-staff-btn", "click", async (e) => {
      const profileId = e.target.dataset.profileId;

      const { response: profile } = await searchStaff({ profileId });

      if (profile) {
        showCreateStaffDialog({
          profileId: profile.id,
          email: profile.email,
          allowedLocations: profile.validator && profile.validator.allowedLocations || [],
          validatorStatus: profile.validator && !profile.validator.disabledOn,
          sellerStatus: profile.seller && !profile.seller.disabledOn,
        });
      } else {
        showError("Profile not found. Reload the page.");
      }
    });
  } catch (err) {
    showError(err);
  }
};

// add staff modal

const staffModel = {
  role: "validator",
};

addOnLoadListener(".staff-tab", "click", (e) => {
  const selectedTab = e.target.dataset.tab;
  clearError();
  staffModel.role = selectedTab;
  showSelectedStaffOverlayTab();
});

function showSelectedStaffOverlayTab() {
  // show content
  const tabs = document.querySelectorAll(".staff-tab-content");
  [].slice.apply(tabs).forEach((e) => {
    e.classList.add("w3-hide");
  });
  const selectedTabId = `staff-tab-content-${staffModel.role}`;
  document.getElementById(selectedTabId).classList.remove("w3-hide");

  // highlight the selection
  [].slice.apply(document.querySelectorAll(".staff-tab")).forEach(e => {
    e.classList.remove("selected");
    if (e.dataset.tab === staffModel.role) {
      e.classList.add("selected");
    }
  });
}

addOnLoadListener("#add-staff", "click", (e) => {
  showCreateStaffDialog({ status: true });
});

function closeCreateStaffOverlay() {
  document.querySelector("#staff-overlay").classList.remove("open");
}

function openCreateStaffOverlay() {
  document.querySelector("#staff-overlay").classList.add("open");
  enableCreateStaffSubmitForm(true);
  showSelectedStaffOverlayTab();
}

const showCreateStaffDialog = ({
  profileId,
  email,
  allowedLocations,
  validatorStatus,
  sellerStatus,
}) => {
  openCreateStaffOverlay();
  document.querySelector("#staff-profile-id").value = profileId || "";
  document.querySelector("#staff-email").value = email || "";
  document.querySelector("#staff-locations").value = allowedLocations || "";
  document.querySelector("#staff-validator-status").checked = !!validatorStatus;
  document.querySelector("#staff-seller-status").checked = !!sellerStatus;
};

const enableCreateStaffSubmitForm = (status) => {
  document.querySelector("#staff-submit").disabled = !status;
};

addOnLoadListener("#staff-submit", "click", async () => {
  const profileId = document.querySelector("#staff-profile-id").value;
  const email = document.querySelector("#staff-email").value;
  const allowedLocationsStr = document.querySelector("#staff-locations").value;
  const status = document.querySelector(`#staff-${staffModel.role}-status`).checked;
  const role = staffModel.role;

  const allowedLocations = allowedLocationsStr.split(/[, ;.]/g);

  try {
    if (!email || !email.length) {
      throw new Error("Email is mandatory");
    }
    if (!allowedLocations.length) {
      throw new Error("Allowed locations list is mandatory");
    }

    enableCreateStaffSubmitForm(false);

    const { response: res } = await updateStaff({ profileId, email, allowedLocations, status, role });

    closeCreateStaffOverlay();

    loadStaffSearch();
  } catch (err) {
    showError(err);
  } finally {
    enableCreateStaffSubmitForm(true);
  }
});

// all overlays
addOnLoadListener(".close-overlay", "click", () => {
  closeRefundOverlay();
  closeCreateBatchOverlay();
  closeCreateStaffOverlay();
  closeBatchPrintOverlay();
});

/**
 * Auth
 */

const getStoredAuth = () => {
  const authStr = sessionStorage.getItem("auth");
  return JSON.parse(authStr);
};

const storeAuth = (auth) => {
  sessionStorage.setItem("auth", JSON.stringify(auth));
};

const clearAuth = () => {
  sessionStorage.removeItem("auth");
};

const auth = {
  state: getStoredAuth(),
};

const msalConfig = {
  auth: {
    clientId: "960e5078-439f-4ef7-aa08-f59af81c9fef",
    authority: "https://login.microsoftonline.com/1c4a2478-5584-49eb-86d1-843966e5179c",
  },
};

const login = () => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  const loginConfig = {};
  msalInstance.loginPopup(loginConfig).then((response) => {
    if (response === null) {
      showError("There was an error authenticating you (response was null)");
      return;
    }

    auth.state = response;

    storeAuth(response);

    refreshAccess();

    clearError();
  }).catch((error) => {
    showError("There was an unexpected error during the login process");
    console.error(error);
    refreshAccess();
  });
};

const logout = () => {
  clearAuth();
  reloadPage();
};

addOnLoadListener("#login-button", "click", (e) => {
  login();
});

addOnLoadListener("#logout-button", "click", (e) => {
  logout();
});

const refreshAccess = () => {
  const hideClass = "w3-hide";
  const loginButton = document.querySelector("#login-button");
  const loginInfoContainer = document.querySelector("#login-info-container");
  const loginInfo = document.querySelector("#login-info");
  if (auth.state) {
    loginButton.classList.add(hideClass);
    loginInfoContainer.classList.remove(hideClass);
    loginInfo.innerHTML = auth.state && auth.state.account && auth.state.account.name;
  } else {
    loginButton.classList.remove(hideClass);
    loginInfoContainer.classList.add(hideClass);
  }
};

addOnLoadAction(refreshAccess);